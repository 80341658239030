import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;

  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0002 33.6668C14.6946 33.6668 12.5279 33.2293 10.5002 32.3543C8.47239 31.4793 6.7085 30.2918 5.2085 28.7918C3.7085 27.2918 2.521 25.5279 1.646 23.5002C0.770996 21.4724 0.333496 19.3057 0.333496 17.0002C0.333496 14.6946 0.770996 12.5279 1.646 10.5002C2.521 8.47239 3.7085 6.7085 5.2085 5.2085C6.7085 3.7085 8.47239 2.521 10.5002 1.646C12.5279 0.770996 14.6946 0.333496 17.0002 0.333496C19.3057 0.333496 21.4724 0.770996 23.5002 1.646C25.5279 2.521 27.2918 3.7085 28.7918 5.2085C30.2918 6.7085 31.4793 8.47239 32.3543 10.5002C33.2293 12.5279 33.6668 14.6946 33.6668 17.0002C33.6668 19.3057 33.2293 21.4724 32.3543 23.5002C31.4793 25.5279 30.2918 27.2918 28.7918 28.7918C27.2918 30.2918 25.5279 31.4793 23.5002 32.3543C21.4724 33.2293 19.3057 33.6668 17.0002 33.6668Z"
        fill="#1D1B20"
      />
      <path
        d="M17.0002 30.3335C20.7224 30.3335 23.8752 29.0418 26.4585 26.4585C29.0418 23.8752 30.3335 20.7224 30.3335 17.0002C30.3335 13.2779 29.0418 10.1252 26.4585 7.54183C23.8752 4.9585 20.7224 3.66683 17.0002 3.66683C13.2779 3.66683 10.1252 4.9585 7.54183 7.54183C4.9585 10.1252 3.66683 13.2779 3.66683 17.0002C3.66683 20.7224 4.9585 23.8752 7.54183 26.4585C10.1252 29.0418 13.2779 30.3335 17.0002 30.3335Z"
        fill="white"
      />
      <path
        d="M10.3335 27.0002L17.0002 21.9168L23.6668 27.0002L21.1668 18.7502L27.8335 14.0002H19.6668L17.0002 5.3335L14.3335 14.0002H6.16683L12.8335 18.7502L10.3335 27.0002Z"
        fill="#1D1B20"
      />
    </svg>
  );
};

IconReviewStar.defaultProps = {
  className: null,
  rootClassName: null,
  isFilled: false,
};

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
